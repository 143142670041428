<template>
    <div id="divaaBigkook">
        <div id="divfixaaBigkook">
            
            
            <div id="divCream4u4ever" @click="showCream4u4ever()">
                <h1 id="hhCream4u4ever">
                    <span>@Cre</span>
                    <img id="imgSmallkookInaa" class="kookturn" src="../assets/cookie.png">
                    <span>m4u 4ever</span>
                </h1>
                <h1 id="hhFeizuoBook">有些事...非做不可!👊</h1>
            </div>


            <div id="divBigkook">
                <div id="divBigkookLeft">
                    <img id="imgBigkookInaa" src="../assets/cookie.png">
                </div>
                <div id="divBigkookRight">
                    <div id="divfixRightTextInaa">
                        <h1 id="text"></h1>
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>
<script>
import anime from 'animejs';
export default {
    methods: {
        showCream4u4ever() {
            window.document.getElementById("hhFeizuoBook").style.opacity = 0;
            anime({
                targets: "#hhCream4u4ever",
                translateY: ["8vh", 0],
                easing: "cubicBezier(0.490, 0.970, 0.540, 0.955)",
                duration: 600,
                complete: () => {
                    anime({
                        targets: "#hhFeizuoBook",
                        opacity: [0, 1],
                        easing: "cubicBezier(0.385, 0.480, 0.685, 0.260)",
                        duration: 100
                    })

                }
            });
        },
        kookturn() {
            anime({
                targets: ".kookturn",
                easing: "linear",
                rotate: "1turn",
                loop: true,
                duration: 1000
            });
        },
        bigkookturn() {
            anime({
                targets: "#imgBigkookInaa",
                easing: "linear",
                rotate: "1turn",
                loop: true,
                duration: 7000
            })
        },
        textTyping() {

            const phrases = ["Hey !Guys !👋", "i'm Maxine\nbut call me Max!🙋", "is this funny? \nu like it?❤️", "u'll see more later👀", "(Repeat🔄)"];
            let currentPhrase = 0;
            let charIndex = 0;
            let typingSpeed = 80;
            let deletingSpeed = 10;
            let textDisplay = document.getElementById("text");
            function type() {
                if (charIndex < phrases[currentPhrase].length) {
                    textDisplay.textContent += phrases[currentPhrase].charAt(charIndex);
                    charIndex++;
                    setTimeout(type, typingSpeed);
                } else {
                    // Wait for 2 seconds after completing the typing of one phrase
                    setTimeout(deleteText, 2000);
                }
            }
            function deleteText() {
                if (charIndex > 0) {
                    textDisplay.textContent = phrases[currentPhrase].substring(0, charIndex - 1);
                    charIndex--;
                    setTimeout(deleteText, deletingSpeed);
                } else {
                    currentPhrase++;
                    if (currentPhrase >= phrases.length) currentPhrase = 0;
                    charIndex = 0; // Reset charIndex to start the next phrase
                    setTimeout(type, typingSpeed);
                }
            }

            // Start the typewriter effect
            setTimeout(type, typingSpeed);

        }

    },
    mounted() {
        this.showCream4u4ever();
        this.kookturn();
        this.bigkookturn();
        this.textTyping();
    }
}
</script>

<style>

#divaaBigkook {
    background-color: #000000;
    height: 736px;
    color: #fff;
    font-family: Be Vietnam Pro;
}

#divfixaaBigkook {
    padding-top: 100px;
}

#divCream4u4ever {
    height: 135px;
    text-align: center;
}


#hhCream4u4ever {
    font-size: 65px;
    font-weight: 500;
}

#imgSmallkookInaa {
    margin-bottom: -6px;
    width: 45px;
    user-select: none;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
}

#hhFeizuoBook {
    padding-top: 10;
    opacity: 0;
    font-size: 25px;
    font-weight: 700;
}


#divBigkook {
    height: 345px;
    display: flex;
}

#divBigkookLeft {
    width: 45vw;
}

#imgBigkookInaa {
    float: right;
    user-select: none;
    width: 333px;

    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
}

#divBigkookRight {
    width: 50vw;
}

#divfixRightTextInaa {
    width: 100%;
    margin-left: 50px;
    margin-top: 83px;
}

#text {
    white-space: pre-wrap;
    font-size: 50px;
    font-weight: 600;
}

@media (max-width: 1250px) {
    #divaaBigkook {
    background-color: #000000;
    height: 500px;
    color: #fff;
    font-family: Be Vietnam Pro;
}

#divfixaaBigkook {
    padding-top: 100px;
}

#divCream4u4ever {
    height: 135px;
    text-align: center;
}


#hhCream4u4ever {
    font-size: 34px;
    font-weight: 500;
}

#imgSmallkookInaa {
    margin-bottom: -6px;
    width: 45px;
    user-select: none;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
}

#hhFeizuoBook {
    padding-top: 10;
    opacity: 0;
    font-size: 25px;
    font-weight: 700;
}


#divBigkook {
    height: 168px;
    display: flex;
}

#divBigkookLeft {
    width: 45vw;
}

#imgBigkookInaa {
    float: right;
    user-select: none;
    width: 160px;

    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
}

#divBigkookRight {
    width: 50vw;
}

#divfixRightTextInaa {
    width: 100%;
    margin-left: 50px;
    margin-top: 48px;
}

#text {
    white-space: pre-wrap;
    font-size: 15px;
    font-weight: 600;
}
   
}
</style>