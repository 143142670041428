<template>
  <div id="divbbNotOnly">
    <div id="divbbNotOnlyfix">


      <div id="divNotonlyInbbfix">
        <h1 id="hhNot">Not only does it make you feel better</h1>
        <h1 id="hhBut">But make you a better person!</h1>
      </div>


      <div id="divKookwFeelwNow">


        <div id="divFeelwNowInbb">
          <h1 id="hhFeelingInbb">Feeling empty? Bored? No passion?</h1>
          <h1 id="hhNowInbb">Now u can "Cre<span><img id="imgNowkookInbb" class="kookturn" @click="showFeelandNow()"
                src="../assets/cookie.png"></span>m" it away!</h1>
        </div>
        <div id="divClickkookInbb">
          <img id="imgClickkookInbb" @click="showFeelandNow()" class="kookturn" src="../assets/cookie.png">
        </div>

      </div>

    </div>

  </div>


</template>

<script>
import anime from 'animejs';


export default {
  methods: {
    showFeelandNow() {
      if (window.matchMedia("(max-width: 1250px)").matches) {
        anime({
          targets: "#divClickkookInbb",
          translateX: -120,
          easing: "easeOutQuint",
          duration: 500,

        })
        anime({
          targets: "#hhFeelingInbb",
          translateX: [100, 15],
          opacity: [0, 1],
          easing: "easeOutQuint",
          duration: 1200,
        })
        anime({
          targets: "#hhNowInbb",
          translateX: [100, 0],
          opacity: [0, 1],
          easing: "easeOutQuint",
          duration: 1500
        })
      }
      else {
        anime({
          targets: "#divClickkookInbb",
          translateX: -310,
          easing: "easeOutQuint",
          duration: 500,

        })
        anime({
          targets: "#hhFeelingInbb",
          translateX: [313, 0],
          opacity: [0, 1],
          easing: "easeOutQuint",
          duration: 1200,
        })
        anime({
          targets: "#hhNowInbb",
          translateX: [313,-59],
          opacity: [0, 1],
          easing: "easeOutQuint",
          duration: 1500
        })

      }

    }
  }
}
</script>
<style>
#divbbNotOnly {
  height: 300px;
}

#divbbNotOnlyfix {
  margin-top: 50px;
}

#divNotonlyInbbfix {
  text-align: center;
}

#hhNot {
  user-select: none;
  font-size: 50px;
}

#hhBut {
  user-select: none;
  font-size: 50px;
}

#divKookwFeelwNow {
  text-align: center;
}
#divClickkookInbb{
  margin-top: -70px;

}

#imgClickkookInbb {
  width: 50px;
}

#divFeelwNowInbb {
  /* float: right; */
  /* margin-top: -55px; */
}


#hhFeelingInbb {
  opacity: 0;
}

#hhNowInbb {
  opacity: 0;
}

#imgNowkookInbb {
  width: 18px;
}





@media (max-width: 1250px) {
#divbbNotOnly {
  height: 150px;
}

#divbbNotOnlyfix {
  margin-top: 50px;
}

#divNotonlyInbbfix {
  text-align: center;
}

#hhNot {
  user-select: none;
  font-size: 18px;
}

#hhBut {
  user-select: none;
  font-size: 18px;
}

#divKookwFeelwNow {
  text-align: center;
}
#divClickkookInbb{
  margin-top: -40px;
  /* margin-bottom: 40px; */

}

#imgClickkookInbb {
  width: 35px;
}

#divFeelwNowInbb {
  font-size: 6px;
  /* float: right; */
  /* margin-top: -55px; */
}


#hhFeelingInbb {
  opacity: 0;
}

#hhNowInbb {
  opacity: 0;
}

#imgNowkookInbb {
  width: 18px;
}

}
</style>
