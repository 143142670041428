<template>
    <div id="divccVx">
        <div id="divfixccVx">
            <div id="divWFGoto">
                <h1 class="stylehhCWFGK">
                    <span>Cre</span>
                    <img id="imgWFGKookIncc" class="kookturn" src="../assets/cookie.png">
                    <span>m? </span>
                </h1>
                <h1 class="stylehhCWFGK">Works? From? Goto?</h1>
                <h1 class="stylehhCWFGK">Click&nbsp; <span @click="subme1()">[me]</span>&nbsp; {{ aatimes }} times to
                    know
                </h1>
            </div>

            <div id="divVxIncc">
                <div id="divVxRightIncc">
                    <img id="imgvxvx" src="../assets/vx.png">
                </div>

                <div id="divVxleftIncc">
                    <h1>你好呀👋</h1>
                    <h1>为了遇见你!我等了</h1>
                    <span id="timeIncc"></span>
                    <h1>⬅️现在让我告诉你一些好玩的事吧！</h1>

                </div>
            </div>
        </div>
    </div>

</template>
<script>
import anime from 'animejs';

export default {
    data() {
        return {
            aatimes: 3,
        }
    },
    methods: {
        calculateTotalSeconds() {
            var targetDate = new Date('2024-02-29T18:38:00');
            var currentDate = new Date();
            var timeDifference = currentDate - targetDate;
            var totalSeconds = Math.floor(timeDifference / 1000);
            document.getElementById("timeIncc").textContent = totalSeconds + "s!";
            // if (window.matchMedia("(max-width: 1250px)").matches) {
            //     document.getElementById("timeIncc").style.fontSize = 18;
            // }
        },
        subme1() {

            if (window.matchMedia("(max-width: 1250px)").matches) {
                if (this.aatimes == 1) {
                    this.aatimes = 0;
                    anime({
                        targets: "#divccVx",
                        height: 536,
                        duration: 500,
                        complete: () => {
                            anime({
                                targets: "#divVxIncc",
                                opacity: [0, 1],
                                duration: 500
                            })
                        }
                    })
                    this.calculateTotalSeconds();
                    setInterval(this.calculateTotalSeconds, 1000);

                }

                if (this.aatimes != 0) {
                    this.aatimes--;
                }

            }
            else {
                if (this.aatimes == 1) {
                    this.aatimes = 0;
                    anime({
                        targets: "#divccVx",
                        height: 750,
                        duration: 500,
                        complete: () => {
                            anime({
                                targets: "#divVxIncc",
                                opacity: [0, 1],
                                duration: 500
                            })
                        }
                    })
                    this.calculateTotalSeconds();
                    setInterval(this.calculateTotalSeconds, 1000);

                }

                if (this.aatimes != 0) {
                    this.aatimes--;
                }
            }
        },

    },

}
</script>
<style>
@media (min-width: 1251px) {

    #divccVx {
        background-color: black;
        height: 400px;
        color: #fff;
        font-family: Be Vietnam Pro;
    }

    #divfixccVx {
        padding-top: 80px;
    }

    #divWFGoto {
        text-align: center;
    }

    #imgWFGKookIncc {
        width: 40px;
        margin-bottom: -4px;

    }

    .stylehhCWFGK {
        user-select: none;
        font-size: 60px;
        font-weight: 500;

    }


    #divVxIncc {
        opacity: 0;
        user-select: none;
        display: flex;

    }

    #divVxRightIncc {
        width: 50vw;
    }

    #imgvxvx {
        width: 300px;
        float: right;
        user-select: none;
        -webkit-user-drag: none;
        -moz-user-drag: none;
        -ms-user-drag: none;
        user-drag: none;
    }

    #divVxleftIncc {
        width: 50vw;
        margin-top: 60px;
        margin-left: 60px;
    }

    #timeIncc {
        user-select: none;
        font-size: 60px;
        font-weight: 500;
    }

}

@media (max-width: 1250px) {
    #divccVx {
        background-color: black;
        height: 220px;
        color: #fff;
        font-family: Be Vietnam Pro;
    }

    #divfixccVx {
        padding-top: 50px;
    }

    #divWFGoto {
        text-align: center;
    }

    #imgWFGKookIncc {
        width: 20px;
        margin-bottom: -4px;

    }

    .stylehhCWFGK {
        user-select: none;
        font-size: 23px;
        font-weight: 500;
    }


    #divVxIncc {
        opacity: 0;
        user-select: none;
        /* display: flex; */
        text-align: center;

    }

    #divVxRightIncc {
        /* width: 50vw; */
    }

    #imgvxvx {
        width: 150px;
        /* float: right; */
        user-select: none;
        -webkit-user-drag: none;
        -moz-user-drag: none;
        -ms-user-drag: none;
        user-drag: none;
    }

    #divVxleftIncc {
        /* width: 50vw; */
        font-size: 10px;
        /* margin-top: 60px;
        margin-left: 60px; */
    }

    #timeIncc {
        user-select: none;
        font-size: 25px;
        font-weight: 500;
    }


}
</style>