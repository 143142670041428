<template>
  <div>
    <AaBigkook></AaBigkook>
    <BbNotOnly></BbNotOnly>
    <cc-vx></cc-vx>
    <!-- <div v-if="screenWidth > 1250"> -->
    <ee-random></ee-random>
    <!-- </div> -->





    <div id="EEEEE">

      <div id="divEndfix">
        <h1 class="End"> - End -</h1>
        <h1 class="Google"> 推荐使用Google Chrome访问 口感更佳(😆)</h1>
      </div>



      <footer class="Footer">
        <h1 class="ICP">ICP&公网安</h1>
        <h1 class="ICPLink"> <a style="color: Black;text-decoration: none" href="https://beian.miit.gov.cn"
            target="_blank">鲁ICP备2024061953号-1</a> </h1>
        <br class="bbr" />
        <img class="Gongan" src="../assets/gongan.png">
        <h1 class="GWALink"><a style="color: Black;text-decoration: none"
            href="https://beian.mps.gov.cn/#/query/webSearch?code=37068202888893" rel="noreferrer"
            target="_blank">鲁公网安备37068202888893</a> </h1>
      </footer>
    </div>


  </div>
</template>

<script>
import AaBigkook from '@/views/aaBigkook.vue';
import BbNotOnly from '../views/bbNotOnly.vue';
import CcVx from '@/views/ccVx.vue';
import EeRandom from '@/views/eeRandom.vue';


export default {
  components: { AaBigkook, BbNotOnly, CcVx, EeRandom },
  data: function () {
    return {
      screenWidth: window.innerWidth,
    }
  },
  mounted() {
    window.addEventListener('resize', this.updateScreenWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateScreenWidth);
  },
  methods: {
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
  }
}

</script>

<style>
#EEEEE {
  height: auto;

}

#divEndfix {
  background-color: #000000;
  padding-top: 30px;
  text-align: center;
  padding-bottom: 30px;
}

.End {
  user-select: none;
  font-size: 2vw;
  color: white;
  margin: 0;
  padding: 0;
}

.Google {
  user-select: none;
  font-size: 1vw;
  color: white;
  margin: 0;
  padding: 0;
}

.Footer {
  background-color: white;
  height: 100px;
  text-align: center;
  user-select: none;
}

.ICP {
  padding-top: 2vw;
  user-select: none;
  color: black;
  font-size: 30px;
}

.ICPLink {
  user-select: none;
  display: inline-block;
  vertical-align: top;
  height: 2vw;
  font-size: 20px;


}

.Gongan {
  user-select: none;
  display: inline-block;
  vertical-align: top;

  height: 2vw;

}

.GWALink {
  user-select: none;
  display: inline-block;
  vertical-align: top;

  height: 2vw;
  font-size: 20px;
  padding-bottom: 2vw;

}

.bbr {
  user-select: none;
}

@media (max-width: 1250px) {
  #EEEEE {
    height: auto;

  }

  #divEndfix {
    background-color: #000000;
    padding-top: 15px;
    text-align: center;
    padding-bottom: 15px;
  }

  .End {
    user-select: none;
    font-size: 14px;
    color: white;
    margin: 0;
    padding: 0;
  }

  .Google {
    user-select: none;
    font-size: 12px;
    color: white;
    margin: 0;
    padding: 0;
  }

  .Footer {
    background-color: white;
    height: 80px;
    text-align: center;
    user-select: none;
  }

  .ICP {
    padding-top: 2vw;
    user-select: none;
    color: black;
    font-size: 17px;
  }

  .ICPLink {
    user-select: none;
    display: inline-block;
    vertical-align: top;
    height: 2vw;
    font-size: 14px;


  }

  .Gongan {
    user-select: none;
    display: inline-block;
    vertical-align: top;

    height: 20px;

  }

  .GWALink {
    user-select: none;
    display: inline-block;
    vertical-align: top;

    height: 2vw;
    font-size: 14px;
    padding-bottom: 2vw;

  }

  .bbr {
    user-select: none;
  }
}
</style>