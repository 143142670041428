<template>
  <div>
    <one-page></one-page>
  </div>
</template>

<script>
import onePage from './components/onePage.vue'

export default {
  components: { onePage },
}

</script>

<style>
</style>