<template>
    <div @click="rrRandomMe()" id="diveeRandom">
        <div id="divFixeeRandom">
            <div id="diveeRandomLeft">
                <div id="divFixLiftimg">
                    <img id="imgLeftInee" src="">
                    <video id="videoLeftInee" src="../assets/story/74.mp4" controls></video>

                </div>
            </div>

            <div id="diveeRandomRight">
                <div id="divFixeeRandomRight">
                    <div id="divFiximgKookInee">
                        <img id="imgkookInee" class="kookturn" src="../assets/cookie.png">
                    </div>
                    <div id="divFixhhInee">
                        <h1 id="hhRightInee"></h1>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            imgFiles: [
                require('@/assets/story/1.jpg'),
                require('@/assets/story/2.jpg'),
                require('@/assets/story/3.jpg'),
                require('@/assets/story/4.jpg'),
                require('@/assets/story/5.jpg'),
                require('@/assets/story/6.jpg'),
                require('@/assets/story/7.jpg'),
                require('@/assets/story/8.jpg'),
                require('@/assets/story/9.jpg'),
                require('@/assets/story/10.jpg'),
                require('@/assets/story/11.jpg'),
                require('@/assets/story/12.jpg'),
                require('@/assets/story/13.jpg'),
                require('@/assets/story/14.jpg'),
                require('@/assets/story/15.jpg'),
                require('@/assets/story/16.jpg'),
                require('@/assets/story/17.jpg'),
                require('@/assets/story/18.jpg'),
                require('@/assets/story/19.jpg'),
                require('@/assets/story/20.jpg'),
                require('@/assets/story/21.jpg'),
                require('@/assets/story/22.jpg'),
                require('@/assets/story/23.jpg'),
                require('@/assets/story/24.jpg'),
                require('@/assets/story/25.jpg'),
                require('@/assets/story/26.jpg'),
                require('@/assets/story/27.jpg'),
                require('@/assets/story/28.jpg'),
                require('@/assets/story/29.jpg'),
                require('@/assets/story/30.jpg'),
                require('@/assets/story/31.jpg'),
                require('@/assets/story/32.jpg'),
                require('@/assets/story/33.jpg'),
                require('@/assets/story/34.jpg'),
                require('@/assets/story/35.jpg'),
                require('@/assets/story/36.jpg'),
                require('@/assets/story/37.jpg'),
                require('@/assets/story/38.jpg'),
                require('@/assets/story/39.jpg'),
                require('@/assets/story/40.jpg'),
                require('@/assets/story/41.jpg'),
                require('@/assets/story/42.jpg'),
                require('@/assets/story/43.jpg'),
                require('@/assets/story/44.jpg'),
                require('@/assets/story/45.jpg'),
                require('@/assets/story/46.jpg'),
                require('@/assets/story/47.jpg'),
                require('@/assets/story/48.jpg'),
                require('@/assets/story/49.jpg'),
                require('@/assets/story/50.jpg'),
                require('@/assets/story/51.jpg'),
                require('@/assets/story/52.jpg'),
                require('@/assets/story/53.jpg'),
                require('@/assets/story/54.jpg'),
                require('@/assets/story/55.jpg'),
                require('@/assets/story/56.jpg'),
                require('@/assets/story/57.jpg'),
                require('@/assets/story/58.jpg'),
                require('@/assets/story/59.jpg'),
                require('@/assets/story/60.jpg'),
                require('@/assets/story/61.jpg'),
                require('@/assets/story/62.jpg'),
                require('@/assets/story/63.jpg'),
                require('@/assets/story/64.jpg'),
                require('@/assets/story/65.jpg'),
                require('@/assets/story/66.jpg'),
                require('@/assets/story/67.jpg'),
                require('@/assets/story/68.jpg'),
                require('@/assets/story/69.jpg'),
                require('@/assets/story/70.jpg'),
                require('@/assets/story/71.jpg'),
                require('@/assets/story/72.jpg'),
                require('@/assets/story/73.jpg'),
                require('@/assets/story/74.mp4'),
                require('@/assets/story/75.mp4'),
                require('@/assets/story/76.mp4'),
                require('@/assets/story/77.mp4'),
                require('@/assets/story/78.mp4'),

            ],
            words: [
                "每次看到都想笑，像拿着1000ml的一样😂...",//1
                "你一定要看看这个...\n超棒！🎉",
                "哈哈哈哈可爱の...",
                "不出意外的话\n应该没有意外了",
                "早就跟你说过，我...",
                "养鱼？！宿舍可以养鱼了？！😺",
                "人生少有的运动会...好久好久了...",
                "额嗯😐...",
                "两个月才到的奖牌...",
                "大航海! One piece!!🚢...",//10
                "我看上帝视角的我...",
                "米国大选，选我哈😄",
                "早起大王是我哈😬",
                "最后一张模糊照片...",
                "你来自哪里？...",
                "你也一定要看这个...超绝！🎉",
                "两个小时学会滑雪，我不是天才？！",
                "最喜欢の电影好吧!🎬",
                "是！那一坨是向日葵🌻...",
                "Kill Bill🔪!!!",//20
                "图片还是表达不了感受...",
                "这个导游我是不会忘的...",
                "饿的时候，真是什么东西都敢买",
                "迷路了...",
                "手机差点没了掉湖里...",
                "远观 🐲...",
                "哈哈哈开船的大爷很有趣...",
                "超远观🐲...",
                "近闻🐲...",
                "啊，说啥呢，忘了...",//30
                "你是说！每天！上学！都能！看到！这个？！",
                "Tom&Jerry",
                "人好多.但真的震撼到我了!",
                "德云社🏠旁边是麦当劳🍔笑鼠了",
                "祖国繁荣昌盛！",
                "你...画的好棒",
                "这么晚了谁出去打球！？🙋",
                "Tomio是🧑🏿",
                "🥝你真行啊你！你就这样对我...",
                "哈哈哈哈哈dada",//40
                "当时还跟你一起看，现在人都找不到了...",
                "嗯！",
                "生了对双胞胎...母子平安哈",
                "你也是个神人👍",
                "啊哦...",
                "掉山里面住了..没网...",
                "我",
                "你",
                "送我这...棵树🌲...",
                "勿扰🚫",//50
                "你要sha了我吗，朋友...",
                "超棒の📖！",
                "临近凌晨五点，准备给你打电话...",
                "喜欢吗？我の朋友？",
                "无法想象，这天我竟然...",
                "你还是太完美了...",
                "告诉我！",
                "👛(哽咽...",
                "这是标志性的一天！我拍了...",
                "请送给17岁的我...",//60
                "这是个自动门...",
                "包中的👌",
                "这谁？...",
                "完美の🍉遇到完美の🥄",
                "我告诉过你这个故事...",
                "Sad...",
                "日常坐牢...",
                "请把我撒进海里...",
                "神清气爽！芜湖！！",
                "这...你..我...谁..",//70
                "随时战斗姿态哈",
                "嗯嗯..",
                "想被暴打请告诉我🎮...",
                "你还是太完美了...",
                "小烧猫going我是吧，中招...",
                "大妹子，我们好像被偷拍了...",
                "(无法形容)",
                "谁拍的🌕？这太棒了！🙋"
            ]
        }
    },
    methods: {
        rrRandomMe: function () {
            var index = Math.floor(Math.random() * this.imgFiles.length);
            var imgStr = this.imgFiles[index];
            window.document.getElementById("hhRightInee").innerText = this.words[index];
            if (index >= 74) {
                window.document.getElementById("videoLeftInee").style.display = "block";
                window.document.getElementById("imgLeftInee").style.display = "none";
                window.document.getElementById("videoLeftInee").src = imgStr;
            }
            else {
                window.document.getElementById("imgLeftInee").style.display = "block";
                window.document.getElementById("videoLeftInee").style.display = "none";
                window.document.getElementById("imgLeftInee").src = imgStr;
            }


        },

    },
    mounted() {
        this.rrRandomMe();
        // setInterval(this.rrRandomMe, 2000)
    }


}

</script>
<style>
@media (min-width: 1251px) {
    #diveeRandom {
        height: 1200px;
        margin-top: 10px;
        margin-bottom: 10px;
        background-color: #000000;
    }

    #divFixeeRandom {
        display: flex;
    }

    #diveeRandomLeft {
        flex: 3;
        background-color: #686161;
        text-align: center;
    }

    #diveeRandomRight {
        flex: 2;
        background-color: #dae6d8;
        text-align: center;
    }

    #divFixLiftimg {
        /* background-color: #fff; */
    }

    #divFixeeRandomRight {
        padding-top: 5%;
    }

    #imgLeftInee {
        width: 60%;
        margin-left: 125px;
    }

    #videoLeftInee {
        width: 60%;
        margin-left: 125px;
    }

    #divFiximgKookInee {}

    #divFixhhInee {
        float: left;
        /* padding-left: 30px; */
    }


    #imgkookInee {
        width: 77px;
    }




    #hhRightInee {}
}

@media (max-width: 1250px) {

    #diveeRandom {
        height: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        background-color: #000000;
    }

    #divFixeeRandom {
        /* display: flex; */
        text-align: center;
    }

    #diveeRandomLeft {
        /* flex: 3; */
        background-color: #686161;
        /* text-align: center; */
    }

    #diveeRandomRight {
        background-color: #dae6d8;
    }


    #divFixeeRandomRight {
        padding-top: 5%;
    }

    #imgLeftInee {
        width: 60%;
        margin-left: 70px;
    }

    #videoLeftInee {
        width: 60%;
        margin-left: 70px;
    }


    #imgkookInee {
        width: 23px;
    }
    #hhRightInee {
        font-size: 15px;
    }

}
</style>